'use strict';

import React from "react";
import PropTypes from "prop-types";

import {FaExclamationTriangle} from "react-icons/fa";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import {isFalse} from "@uw-it-sis/lib-react/lib/Utils";

import {ProtocolLabels} from "./utils/Constants";
import {phoneFormat} from "./utils/Utils";
import ProfileStore from "./ProfileStore";


const EndpointCheckbox = function (props) {
    const endpoint = props.endpoint;
    let verifyMessage;
    let isDisabled = props.disabled;
    let isChecked = props.checked
    if ( endpoint.hasOwnProperty("Active") && isFalse(endpoint.Active) ) {
        isDisabled = true;
        isChecked = false;
        verifyMessage =
            <Alert variant="warning" size="sm" className="d-flex align-items-start mt-2 mb-0 p-2 space-x-2">
                <span className="lh-1"><FaExclamationTriangle aria-hidden={true} className="fs-6" /></span>
                <small>{ProtocolLabels[endpoint.Protocol].text} confirmation is pending. Reply to the confirmation text sent to your mobile phone. <Button variant="inline-link" onClick={ProfileStore.resendSmsVerification}>Resend the confirmation</Button></small>
            </Alert>
    }

    let address = endpoint.EndpointAddress;
    if (endpoint.Protocol === 'sms') {
        address = phoneFormat(endpoint.EndpointAddress);
    }

    return (
        <div>
            <Form.Check id={`option-${endpoint.Protocol}`}
                        type="checkbox">
                <Form.Check.Input
                    name={endpoint.Protocol}
                    type="checkbox"
                    checked={isChecked}
                    onChange={props.onChange}
                    disabled={isDisabled} />
                <Form.Check.Label className="space-x-2">
                    <span>{ProtocolLabels[endpoint.Protocol].text}</span>
                    <small className="text-muted">{address}</small>
                </Form.Check.Label>
            </Form.Check>
            {verifyMessage}
        </div>

    )
}

EndpointCheckbox.propTypes = {
    endpoint: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool
}

EndpointCheckbox.defaultProps = {
    disabled: false,
    checked: false,
    onChange: () => {} // no-op
}

export default EndpointCheckbox;