import React from "react";
import {FaRegCommentDots, FaRegEnvelope} from "react-icons/fa";

export const NotifyEvents = {
    // For reloading all subscriptions
    subscriptionsChanged: "Notify.subscriptionsChanged",
    // For closing edit subscription modal after update
    subscriptionUpdated: "Notify.subscriptionUpdated"
}

export const ProtocolLabels = {
    "email": {
        text: "Email",
        icon: FaRegEnvelope,
        combo: (
            <>
                <FaRegEnvelope title="Email" aria-hidden={true} className="fs-4 me-2" />
                <span>Email</span>
            </>
        )
    },
    "sms": {
        text: "SMS/Text",
        icon: FaRegCommentDots,
        combo: (
            <>
                <FaRegCommentDots title="SMS/Text" aria-hidden={true} className="fs-4 me-2" />
                <span>SMS/Text</span>
            </>
        )
    }
}

export const RegEx = {
    // https://emailregex.com
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // phone number e.g. (123) 456-7890
    phone: /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/,
    // phone digits with international code
    number: /^\+[1](\d{3})(\d{3})(\d{4})$/,
    // sln as 5 digits
    sln: /^\d{5}$/
};