'use strict';

// Pull in the styles ...
import './main/scss/main.scss';

import React from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';

import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import {AppContext} from "@uw-it-sis/lib-react/lib/AppContext";
import App from "@uw-it-sis/lib-react/lib/App";
import history from '@uw-it-sis/lib-react/lib/History';
import AppHeaderDefault from "@uw-it-sis/lib-react/lib/AppHeaderDefault";
import AppFooterDefault from "@uw-it-sis/lib-react/lib/AppFooterDefault";
import {render} from '@uw-it-sis/lib-react/lib/Render';

import Notify from "./main/js/Notify";

const footerLinks = [
    {url: 'https://itconnect.uw.edu/learn/tools/notify-uw/', text: 'Notify Help'},
    {url: 'mailto:help@uw.edu', text: 'Contact'},
    {url: 'http://www.washington.edu/online/privacy', text: 'Privacy'},
    {url: 'http://www.washington.edu/online/terms', text: 'Terms'}
];

/**
 * Define the routes.  The /course route handles incoming requests from other apps to individual courses.
 */
const routes =
    <Routes>
        <Route path="/" element={<Notify />} />
        <Route path="course/:year/:quarter/:sln" element={<Notify />} />
    </Routes>;

/**
 * Put the AppStore in the React context so that it's available to any class that needs it.
 */
const router = (
    <AppContext.Provider value={{
        appStore: appStore,
        history: history
    }}>
        <HashRouter>
            <App name="notify"
                 title="Notify.UW"
                 routes={routes}
                 header={<AppHeaderDefault title="Notify.UW" />}
                 footer={<AppFooterDefault links={footerLinks} />}
                 showAppNavigation={false} />
        </HashRouter>
    </AppContext.Provider>
);

render(router);
