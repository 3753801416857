'use strict';

import moment from "moment";

import appStore from '@uw-it-sis/lib-react/lib/AppStore';
import {isEmpty} from "@uw-it-sis/lib-react/lib/Utils";
import TermUtils from "@uw-it-sis/lib-react/lib/TermUtils";

import {parseSurrogateId} from "./Utils";

class Api {

    constructor() {
        this.base_url = "api";
    }

    createProfile(profile) {
        return appStore.getHttpClient().doPost(`${this.base_url}/profile`, profile, 'createProfile');
    }

    getProfile() {
        return appStore.getHttpClient().doGet(`${this.base_url}/profile`, 'getProfile');
    }

    createProfileEndpoint(endpoint) {
        return appStore.getHttpClient().doPost(`${this.base_url}/profile/endpoint`, endpoint, 'createProfileEndpoint');
    }

    updateProfileEndpoint(endpoint) {
        return appStore.getHttpClient().doPut(`${this.base_url}/profile/endpoint/${endpoint.EndpointID}`,
            endpoint, 'updateProfileEndpoint');
    }

    deleteProfileEndpoint(endpoint) {
        return appStore.getHttpClient().doDelete(`${this.base_url}/profile/endpoint/${endpoint.EndpointID}`,
            endpoint, 'deletesProfileEndpoint');
    }

    createSubscription(subscription) {
        return appStore.getHttpClient().doPost(`${this.base_url}/profile/subscriptions`, subscription, "createSubscription");
    }

    deleteSubscription(subscriptionId) {
        return appStore.getHttpClient().doDelete(`${this.base_url}/profile/subscriptions/${subscriptionId}`, "deleteSubscription");
    }

    async getSectionDetails(section) {
        let url = `${this.base_url}/channel/section?courseCode=${encodeURIComponent(section.courseCode)}`;
        return await appStore.getHttpClient().doPost(url, { slns: [section.sln]}, 'getSectionDetails');
    }

    // Get terms based on the filter (published, plannable)
    async getTerms(filter) {
        let url = `${this.base_url}/channel/terms?filter=${filter}`;
        return await appStore.getHttpClient().doGet(url, 'getTerms');
    }

    async searchChannels(sln, year, quarter) {
        let channelData = await appStore.getHttpClient().doGet(`${this.base_url}/channel/search?sln=${sln}&year=${year}&quarter=${quarter}`, 'searchChannels');

        let results = [];

        if (channelData && !isEmpty(channelData.Channels)) {
            for (let channel of channelData.Channels) {
                let parsedCode = parseSurrogateId(channel.SurrogateID);

                if (parsedCode.courseCurriculumAbbr == "TRAIN") {
                    // EDGE CASE: this is a TRAIN section which we want to support for test/validation/qa purposes, but the
                    // MyPlan check will fail for TRAINs so we'll just push it through anyways.
                    results.push(this.buildChannel(channel, parsedCode, {}));
                    return results
                }

                let sectionDetails = await this.getSectionDetails(parsedCode);
                // Does sectionDetails contain term
                let hasTerm = false;
                // Does sectionDetails contain an offering with SLN
                let hasSln = false;
                // Dig through section details to find if hasTerm and has Sln
                if ( sectionDetails.hasOwnProperty("courseOfferingInstitutionList") && sectionDetails.courseOfferingInstitutionList.length > 0 ) {
                    sectionDetails.courseOfferingInstitutionList.forEach(institution => {
                        if ( institution.hasOwnProperty("courseOfferingTermList") && institution.courseOfferingTermList.length > 0 ) {
                            institution.courseOfferingTermList.forEach(item => {
                                if ( !hasTerm && item.hasOwnProperty("term")) {
                                    hasTerm = item.term.toLowerCase() === `${quarter} ${year}`;
                                }

                                if ( item.hasOwnProperty("activityOfferingItemList") && item.activityOfferingItemList.length > 0 ) {
                                    item.activityOfferingItemList.forEach(offering => {
                                        if ( !hasSln && offering.hasOwnProperty("registrationCode") ) {
                                            hasSln = offering.registrationCode === sln;
                                        }
                                    });
                                }
                            });
                        }
                    });
                }

                if (hasTerm && hasSln) {
                    results.push(this.buildChannel(channel, parsedCode, sectionDetails));
                }
            }
        }

        return results;
    }

    async getSubscriptions(publishedTerms) {
        let subscriptionData = await appStore.getHttpClient().doGet(`${this.base_url}/profile/subscriptions`, 'getSubscriptions');

        // Sort the subscriptions so that the newest ones are last
        subscriptionData.Subscriptions.sort((obj1, obj2) => moment(obj1.Created).isAfter(obj2.Created) ? 1 : -1);

        // Return true if the subscription is in the given term
        function isSubscriptionInTerm(sub, term) {
            const termName = sub.Channel.Tags.quarter + " " + sub.Channel.Tags.year;
            const termId = TermUtils.makeTermIdFromTermName(termName);
            return term.id === termId;
        }

        // Only fetch details for published terms
        let activeSubscriptions = subscriptionData.Subscriptions.filter(sub => {
            return publishedTerms.some(term => isSubscriptionInTerm(sub, term));
        });

        let byChannelMap = {};

        for( let sub of activeSubscriptions ) {

            let channelId = sub.Channel.ChannelID;
            let endpointType = sub.Endpoint.Protocol;

            let subscriptionByChannelEntry = byChannelMap[channelId];

            if (isEmpty(subscriptionByChannelEntry) ) {

                let parsedCourseCode = parseSurrogateId(sub.Channel.SurrogateID);
                parsedCourseCode.sln = sub.Channel.Tags.sln;

                let sectionDetails;
                if (parsedCourseCode.courseCurriculumAbbr == "TRAIN") {
                    // EDGE CASE: this is a TRAIN section which we want to support for test/validation/qa purposes, but the
                    // MyPlan check will fail for TRAINs so we'll just push it through anyways.
                    sectionDetails = {};
                } else {
                    sectionDetails = await this.getSectionDetails(parsedCourseCode);
                }

                let channelDetails = this.buildChannel(sub.Channel, parsedCourseCode, sectionDetails);

                let courseSubscription = {
                    ...channelDetails,
                    endpoints: [ {
                        id: sub.Endpoint.EndpointID,
                        type: endpointType,
                        address: sub.Endpoint.EndpointAddress
                    } ]
                };

                byChannelMap[channelId] = {
                    courseSubscription: courseSubscription,
                    [endpointType]: sub,
                };
            }
            else {
                // if we find another subscription for the same channel, then the endpoint is the only part that is different
                subscriptionByChannelEntry.courseSubscription.endpoints.push({
                    id: sub.Endpoint.EndpointID,
                    type: endpointType,
                    address: sub.Endpoint.EndpointAddress
                });

                subscriptionByChannelEntry[endpointType] = sub;
            }
        }

        let result = Object.values(byChannelMap).map(c => c.courseSubscription);

        return { "subscriptionList": result, "subscriptionsByChannelId": byChannelMap };
    }

    buildChannel(rawChannelData, parsedCode, sectionDetails) {
        let result = {
            ...parsedCode,
            channelId: rawChannelData.ChannelID,
            courseTitle: rawChannelData.Name,
            sln: rawChannelData.Tags.sln,
            section: {}
        };

        if (
            sectionDetails.hasOwnProperty("courseOfferingInstitutionList")
            &&
            sectionDetails.courseOfferingInstitutionList.length > 0
            &&
            sectionDetails.courseOfferingInstitutionList[0].hasOwnProperty("courseOfferingTermList")
            &&
            sectionDetails.courseOfferingInstitutionList[0].courseOfferingTermList.length > 0
            &&
            sectionDetails.courseOfferingInstitutionList[0].courseOfferingTermList.find(item => item.term === `${parsedCode.quarter} ${parsedCode.year}`)
        ) {
            result.section = sectionDetails.courseOfferingInstitutionList[0].courseOfferingTermList.find(item => item.term === `${parsedCode.quarter} ${parsedCode.year}`).activityOfferingItemList[0];
        }

        return result;
    }

}

export default new Api();