'use strict';

import React, {Component} from 'react';
import {observer} from "mobx-react";
import PropTypes from 'prop-types';

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import {States} from "@uw-it-sis/lib-react/lib/AppConstants";

import SubscriptionFormStore from "./SubscriptionFormStore";
import profileStore from "./ProfileStore";
import EndpointCheckbox from "./EndpointCheckbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classnames from "classnames";

class SubscriptionForm extends Component {

    constructor(props) {
        super(props);

        this.props = props;
        this.store = new SubscriptionFormStore();
        this.handleUpdateNotification = this.handleUpdateNotification.bind(this);
    }

    componentDidMount() {
        this.store.mount(this.props.subscription);
    }

    componentWillUnmount() {
        this.store.unmount();
    }

    updateProtocol(e) {
        e.stopPropagation();
        this.store.toggleFormValue(e.target.name);
    }

    handleUpdateNotification(e) {
        e.preventDefault();
        // allow action as long as one protocol is checked
        if (this.store.formData.email || this.store.formData.sms) {
            this.store.saveChanges();
        }
    }

    render() {
        const isPending = this.store.state === States.pending;
        let errorMessage;
        if (this.store.formData.errorMessage) {
            errorMessage = <Alert variant="danger">{this.store.formData.errorMessage}</Alert>
        }

        let endpointInputs = profileStore.getEndpoints().map((endpoint, i) => {
            return (
                <EndpointCheckbox
                    key={i}
                    endpoint={endpoint}
                    onChange={(e) => this.updateProtocol(e)}
                    disabled={!profileStore.hasEndpoint(endpoint.Protocol) || isPending}
                    checked={this.store.formData[endpoint.Protocol]}/>
            );
        });

        let updateButton =
                <Button
                    variant="primary"
                    type="submit"
                    className={classnames({"disabled": !this.store.formData.email && !this.store.formData.sms})}
                    aria-disabled={!this.store.formData.email && !this.store.formData.sms}>
                    Update
                </Button>;
        if (isPending) {
            updateButton =
                <Button
                    variant="primary"
                    disabled>
                    <Spinner animation="border" size="sm" className="me-2 align-middle" aria-hidden="true"/>
                    <span className="align-middle">Update</span>
                </Button>;
        }

        return (
            <Form onSubmit={this.handleUpdateNotification}>
                <Row className="g-1">
                    <Col xs={12} md={8}>
                        {this.props.children}
                    </Col>
                    <Col xs={12} md={4} className="mt-3 mt-md-0 space-y-3">
                        {endpointInputs}
                    </Col>
                </Row>
                <ButtonToolbar className="mt-3 pt-3 justify-content-end">
                    {updateButton}
                    <Button
                        variant="secondary"
                        onClick={(e) => this.props.closeForm(e)}>
                        Close
                    </Button>
                </ButtonToolbar>
                {errorMessage}
            </Form>
        );
    }
}

SubscriptionForm.propTypes = {
    closeForm: PropTypes.func.isRequired,
    subscription: PropTypes.object.isRequired
}

export default observer(SubscriptionForm);