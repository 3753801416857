'use strict';

import React, {Component} from 'react';
import {observer} from "mobx-react";
import PropTypes from 'prop-types';
import classnames from "classnames";

import {FaExclamationCircle} from "react-icons/fa";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import {isFalse} from "@uw-it-sis/lib-react/lib/Utils";

import newSubscriptionStore from "./NewSubscriptionStore";
import profileStore from "./ProfileStore";
import {handleInputNumbersOnly, handleInputMaxLength} from "./utils/Utils";
import EndpointCheckbox from "./EndpointCheckbox";

class NewSubscriptionForm extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.store = new newSubscriptionStore();
        this.state = {
            slnIsInvalid: false
        };

        this.handleAddNotification = this.handleAddNotification.bind(this);
    }

    componentDidMount() {
        this.store.mount(this.props.year, this.props.quarter);

        // validate the incoming sln
        if (this.props.incomingSln) {
            if (this.isValidSln(this.props.incomingSln)) {
                this.store.updateSln(this.props.incomingSln);
            }
            else {
                this.setState({slnIsInvalid: true});
            }

            // scroll to the button to submit if there is an incoming SLN
            document.getElementById(`addNotificationButton${this.props.year}${this.props.quarter}`).focus();
        }
    }

    updateProtocol(e) {
        e.stopPropagation();
        this.store.toggleFormValue(e.target.name)
    }

    updateSln(e) {
        e.stopPropagation();
        let sln = e.target.value;
        this.store.updateSln(sln);

        if (this.state.slnIsInvalid) {
            this.validateSlnInput(e);
        }
    }

    handleAddNotification(e) {
        e.preventDefault();
        // Only allow action taken if button is not disabled
        if (!this.store.isAddNotificationDisabled()) {
            this.store.slnSearchAndSubscribe();
        }
    }

    isValidSln(sln) {
        let valueEmpty = (sln === "");
        let valueInvalid = isFalse(/^\d{5}$/.test(sln));

        // Return true only if the value is not empty and is exactly 5 digits
        return !(valueEmpty || valueInvalid);
    }

    validateSlnInput(e) {
        const target = e.currentTarget;
        const value = target.value;

        this.setState({slnIsInvalid: !this.isValidSln(value)});
    }

    render() {
        const isPending = this.store.state === States.pending;
        let errorMessage;
        if (this.store.formData.errorMessage) {
            errorMessage =
                <Col xs={12}>
                    <Alert variant="danger" className="mt-3 mb-0 d-inline-block">
                        <FaExclamationCircle className="me-2" aria-hidden="true"/>
                        {this.store.formData.errorMessage}
                    </Alert>
                </Col>;
        }

        let buttonComponent =
            <Button
                id={`addNotificationButton${this.props.year}${this.props.quarter}`}
                variant="primary"
                type="submit"
                className={classnames("mt-4", {"disabled": this.store.isAddNotificationDisabled()})}
                aria-disabled={this.store.isAddNotificationDisabled()}>
                Add Notification
            </Button>;
        if (isPending) {
            buttonComponent =
                <Button
                    variant="primary"
                    className="mt-4"
                    disabled>
                    <Spinner animation="border" size="sm" className="me-2 align-middle" aria-hidden="true"/>
                    <span className="align-middle">Add New Notification</span>
                </Button>;
        }

        let endpointInputs = profileStore.getEndpoints().map((endpoint, i) => {
            return (
                <Col xs={12} md={6} key={i}>
                    <EndpointCheckbox
                        endpoint={endpoint}
                        onChange={(e) => this.updateProtocol(e)}
                        disabled={!profileStore.hasEndpoint(endpoint.Protocol) || isPending}
                        checked={this.store.formData[endpoint.Protocol]} />
                </Col>
            );
        });

        return (
            <Form onSubmit={this.handleAddNotification}>
                <h3 className="h3">Add new notification for {this.props.quarter} {this.props.year}</h3>
                <p>Enter an SLN to add a seat availability notification.</p>
                <Row className="space-y-3 space-y-sm-0">
                    <Col xs={12} sm={4} md={3}>
                        <Form.Group controlId="notification-sln" className="mb-0">
                            <Form.Label className="sr-only">SLN</Form.Label>
                            <Form.Control
                                type="number"
                                className="px-2"
                                style={{width: "8rem"}}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                maxLength={5}
                                pattern="[0-9]*"
                                placeholder="e.g. 12345"
                                required={true}
                                onBlur={(e) => this.validateSlnInput(e)}
                                onInput={handleInputMaxLength}
                                onKeyDown={handleInputNumbersOnly}
                                onChange={(e) => this.updateSln(e)}
                                value={this.store.formData.sln}
                                aria-invalid={this.state.slnIsInvalid}
                                isInvalid={this.state.slnIsInvalid} />
                            <Form.Control.Feedback type="invalid" aria-live="polite">
                                Must enter 5 digits
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={8} md={9}>
                        <Row className="space-y-3 space-y-md-0">
                            {endpointInputs}
                        </Row>
                    </Col>
                    <Col xs={12}>
                        {buttonComponent}
                    </Col>
                    {errorMessage}
                </Row>
            </Form>
        );
    }
}

NewSubscriptionForm.propTypes = {
    year: PropTypes.string.isRequired,
    quarter: PropTypes.string.isRequired,
    incomingSln: PropTypes.string,
    isModal: PropTypes.bool
}

NewSubscriptionForm.defaultProps = {
    isModal: false
}

export default observer(NewSubscriptionForm);