'use strict';

import { observable, configure, flow, action, makeObservable } from 'mobx';
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import {isFalse, capitalize} from '@uw-it-sis/lib-react/lib/Utils';
import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import events from "@uw-it-sis/lib-react/lib/Events";

import api from "./utils/Api";
import profileStore from "./ProfileStore";
import {NotifyEvents} from "./utils/Constants";
import AnnouncerStore from "@uw-it-sis/lib-react/lib/AnnouncerStore";

class NewSubscriptionStore {

    state = States.initial;

    formData = {
        sln: "",
        email: false,
        sms: false,
        year: "",
        quarter: "",
        errorMessage: ""
    };

    constructor() {
        makeObservable(this, {
            state: observable,
            formData: observable,
            mount: action,
            toggleFormValue: action,
            updateSln: action,
            clearSearch: action
        });

        this.componentKey = "NewSubscription";

        this.subscriptions = [];
    }

    mount(year, quarter) {
        this.formData.sln = "";
        this.formData.year = year;
        this.formData.quarter = quarter.toLowerCase();
        if ( profileStore.hasEndpoint("email") ) {
            let emailEndpoint = profileStore.getEmailEndpoint();
            this.formData.email = emailEndpoint.hasOwnProperty("Active") && emailEndpoint.Active;
        }
        if ( profileStore.hasEndpoint("sms") ) {
            let smsEndpoint = profileStore.getSmsEndpoint();
            this.formData.sms = smsEndpoint.hasOwnProperty("Active") && smsEndpoint.Active;
        }
    }

    toggleFormValue(prop) {
        this.formData[prop] = !this.formData[prop];
        if (this.formData.errorMessage !== "") {
            this.formData.errorMessage = "";
        }
    }

    updateSln(sln) {
        this.formData.sln = sln;
        if (this.formData.errorMessage !== "") {
            this.formData.errorMessage = "";
        }
    }

    slnSearchAndSubscribe = flow(function* () {
        this.formData.errorMessage = "";

        this.state = States.pending;

        try {
            this.subscriptions = yield api.searchChannels(this.formData.sln, this.formData.year, this.formData.quarter);

            if (appStore.isDebug()) {
                console.log(`NewSubscriptionStore:slnSearchAndSubscribe completed retrieving [${this.subscriptions.length}] channels`)
            }

            if (this.subscriptions.length === 0) {
                this.formData.errorMessage = `Not a valid SLN for ${capitalize(this.formData.quarter)} ${this.formData.year}`;
            } else {
                let subscriptionItem = this.subscriptions[0];
                let endpointsForCreate = [];

                if (this.formData.email) {
                    endpointsForCreate.push({
                        EndpointID: profileStore.profile.Person.Endpoints.find( (e) => {return e.Protocol === "email"}).EndpointID,
                        Protocol: "email"
                    });
                }

                if (this.formData.sms) {
                    endpointsForCreate.push({
                        EndpointID: profileStore.profile.Person.Endpoints.find( (e) => {return e.Protocol === "sms"}).EndpointID,
                        Protocol: "sms"
                    });
                }

                for (let endpoint of endpointsForCreate) {
                    let personSubscriberId = profileStore.profile.Person.SurrogateID;

                    let apiData = {
                        "Channel": {
                            "ChannelID": subscriptionItem.channelId
                        },
                        "Endpoint": {
                            ...endpoint,
                            "SubscriberID": personSubscriberId
                        }
                    };

                    yield api.createSubscription(apiData);
                }

                events.emit(NotifyEvents.subscriptionsChanged);

                AnnouncerStore.announce(`Notification for ${subscriptionItem.courseCode} has been added to ${subscriptionItem.quarter} ${subscriptionItem.year}`);
            }
        }
        catch (error) {
            if (error.exceptionCode === "ALREADY_EXISTS") {
                this.clearSearch();
            } else {
                this.state = States.error;
            }
            return;
        }

        this.state = States.done;
    }.bind(this));

    clearSearch() {
        this.state = States.pending;

        this.subscriptions = [];
        this.formData.errorMessage = "";
        this.formData.sln = "";
        this.state = States.done;
    }

    isAddNotificationDisabled() {
        return isFalse(/^\d{4}$/.test(this.formData.year))
        ||
        this.formData.quarter === '' || this.formData.quarter === null
        ||
        isFalse(/^\d{5}$/.test(this.formData.sln))
        ||
        (isFalse(this.formData.sms) && isFalse(this.formData.email));
    }
}

export default NewSubscriptionStore;